<template>
  <equipment-layer />
</template>

<script>
import { ref } from '@vue/reactivity'
import EquipmentLayer from '../equipmentLayer/index.vue'
export default {
  name: 'MonitoringLayer',
  components: {
    EquipmentLayer
  },
  setup() {
    const isLayer = ref(1)
    return { isLayer }
  }
}
</script>
    
    <style></style>
    